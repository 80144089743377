import { Box, styled } from "@mui/material";
import { Link } from "@remix-run/react";
import { FC } from "react";
import BazaarImage from "~/components/BazaarImage";
import { Span } from "~/components/Typography";

// custom styled components
const CardWrapper = styled(Box)(() => ({
  maxHeight: 240,
  overflow: "hidden",
  borderRadius: "10px",
  position: "relative",
  // "& img": { transition: "0.3s" },
  // ":hover": { img: { transform: "scale(1.1)" } },
}));
const CardContent = styled(Box)<{ contentalign: "right" | "left" }>(
  ({ contentalign }) => ({
    top: 0,
    zIndex: 1,
    color: "#fff",
    height: "100%",
    display: "flex",
    position: "absolute",
    flexDirection: "column",
    justifyContent: "center",
    ...(contentalign === "left"
      ? { left: 32 }
      : { right: 32, alignItems: "flex-end" }),
  })
);

const CardLink = styled(Span)(() => ({
  position: "relative",
  paddingBottom: "2px",
  textTransform: "uppercase",
  // backgroundColor: "rgba(0,0,0, 0.4)",
  ":hover::after": { width: "100%" },
  ":after": {
    left: 0,
    bottom: 0,
    width: "0%",
    content: "''",
    height: "2px",
    transition: "0.3s",
    position: "absolute",
    backgroundColor: "white",
  },
}));

// ========================================================
type SalesBannerCard2Props = {
  img: string;
  url: string;
  contentPosition: "left" | "right";
};
// ========================================================

const SalesBannerCard2: FC<SalesBannerCard2Props> = ({
  img,
  url,
  contentPosition,
}) => {
  return (
    <CardWrapper>
      {/* <Link to={url}> */}
      <BazaarImage alt="category" height="100%" width="100%" src={img} />
      <CardContent
        contentalign={contentPosition}
        className="content"
      ></CardContent>
      {/* </Link> */}
    </CardWrapper>
  );
};

export default SalesBannerCard2;
